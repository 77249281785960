export interface NavigationItem {
  label: string;
  url: string;
  icon: string;
  ability?: string | string[];
  children?: Pick<NavigationItem, 'label' | 'url' | 'ability'>[];
};

export default [
  { label: 'Dashboard', url: '/dashboard', icon: 'ph:gauge' },
  { label: 'Locations', url: '/location', icon: 'ph:map-pin-line', ability: 'location-list' },
  { label: 'Companies', url: '/companies', icon: 'ph:buildings', ability: 'company-list' },
  {
    label: 'Tickets',
    url: '/ticket',
    icon: 'ph:ticket',
    ability: 'ticket-list',
    children: [
      { label: 'All', url: '/ticket' },
      { label: 'Open', url: '/ticket/status/1' },
      { label: 'Pending', url: '/ticket/status/2' },
      { label: 'Closed', url: '/ticket/status/3' },
    ],
  },
  {
    label: 'Registrations',
    url: '/access-request/announced',
    icon: 'ph:door-open',
    ability: 'accessrequest-announcement-list',
    children: [
      { label: 'Announced', url: '/access-request/announced', ability: 'accessrequest-registration-list' },
      { label: 'Signed In', url: '/access-request/signedin', ability: 'accessrequest-registration-list' },
      { label: 'Signed Out', url: '/access-request/signedout', ability: 'accessrequest-registration-list' },
    ],
  },
  {
    label: 'Shipment',
    url: '/shipment/announced',
    icon: 'ph:truck',
    ability: 'shipment-registration-list',
    children: [
      { label: 'Announced', url: '/shipment/announced' },
      { label: 'Received', url: '/shipment/received' },
      { label: 'Delivered', url: '/shipment/delivered' },
    ],
  },
  { label: 'Schedule', url: '/schedule', icon: 'ph:calendar', ability: 'roster-view' },
  { label: 'Books', url: '/books', icon: 'ph:book', ability: 'book-list' },
  {
    label: 'Forms',
    url: '/form-flows',
    icon: 'ph:textbox',
    ability: 'form-flow-item-list',
    children: [
      { label: 'Overview', url: '/form-flows' },
      { label: 'Form Templates', url: '/form-template/all', ability: 'form-flow-list' },
    ],
  },
  { label: 'Reporting', url: '/reporting', icon: 'ph:chart-bar', ability: 'report-list' },
  {
    label: 'System',
    url: '/system',
    icon: 'ph:gear',
    ability: 'system-list',
    children: [
      { label: 'Users', url: '/system/user', ability: 'user-list' },
      { label: 'Permissions', url: '/system/acl/acl', ability: ['acl-list', 'acl-module-template-list'] },
      { label: 'Books', url: '/system/docs/book', ability: 'book-list' },
      { label: 'API', url: '/system/api/client', ability: 'api-client-list' },
      { label: 'Firewall', url: '/system/firewall', ability: 'firewall-list' },
      { label: 'Entities', url: '/system/entity', ability: 'entity-type-list' },
      { label: 'Pages', url: '/system/form', ability: 'form-list' },
      { label: 'Logbook', url: '/system/activity', ability: 'event-list' },
      { label: 'Files', url: '/system/file', ability: 'system-file' },
      { label: 'Tickets', url: '/system/ticket/ticket-category', ability: 'ticket-configure' },
      { label: 'Authorization Types', url: '/settings/authorizations/authorization-type', ability: 'authorization-types-configure' },
      { label: 'Visitor Types', url: '/settings/access-request/visitor-type', ability: 'accessrequest-visitor-type-configure' },
      { label: 'Qualifications', url: '/system/qualifications', ability: 'qualification-edit' },
      { label: 'Notifications', url: '/notification/group', ability: 'notification-group-list' },
      { label: 'SAML', url: '/system/saml/idp', ability: 'saml-idp-list' },
      { label: 'Status', url: '/system/status', ability: 'system-status' },
      { label: 'Settings', url: '/system/settings/index', ability: 'system-setting' },
    ],
  },
] as NavigationItem[];
